import React, {useState} from 'react';
import Layout from '../../../components/layouts';
import SEO from '../../../components/common/SEO';
import './scratch-cards.scss';
import Banner from '../../../components/features/Banner'
import { graphql } from 'gatsby';
import SignUpBtn from '../../../components/features/SignUpBtn'
import Badge from '../../../components/badge/badge';
import ReactWOW from 'react-wow';

export const query = graphql`
  query ScratchCards {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
    features: prismic {
        allFeatures {
            edges{
                node {
                    meta_title
                    meta_description
                    social_title
                    social_description
                    banner_title
                    banner_subtitle
                    banner_feature_name
                    banner_right_button_text
                    banner_right_button_source
                    question_text
                    question_answer
                    question_bullets {
                        bullet_text
                    }
                    question_image
                    video_source {
                        _linkType
                        __typename
                    }
                    video_statement {
                        text
                    }
                    information_list {
                        title
                        subtitle
                    }
                    steps_title
                    steps_subtitle
                    step_list {
                        image_source
                        title
                        subtitle
                    }
                    
                }
            }
        }
    }
  }
`

 export default function ScratchCards({data}){
    const features = data.features.allFeatures;
    const [toggler, setToggler] = useState(false)
    const lightboxProps = {
        toggler: toggler,
        setToggler: setToggler,
        sources: ["https://www.youtube.com/watch?v=xE2yXvWB038"],
    }

    const seo = {
        title: 'WorkHub | Safety Scratch Cards Create Custom Scratch Off Cards',
        description: 'Order customized scratch cards to handout when you see employees helping others, wearing ppe and following safety protocols.',
        image: '/features/scratch-cards/social-card.jpg',
    }

    const bannerProps = {
        title: 'Scratch Cards',
        // subtitle: `Scratch cards are an optional add-on within our Recognition Program to reward people who are exhibiting top-rate performance. Order custom cards with your logo and messaging and distribute them to supervisors who can hand them when they see your workers doing something exemplary, such as: following protocols to a tee, looking out for the new guy, consistently rocking their PPE or going out of the way to help others.`,
        featureName: 'scratch cards',
        mockupImg: '/features/scratch-cards/hero.png'
    }

    return (
        <Layout {...lightboxProps}>
            <SEO {...seo} {...data.site}/>
            <Banner {...bannerProps} {...lightboxProps}/>
            <main className="feature-main">
                <section className="feature-section header">
                    <div className='sc_feature_header'>
                        <h1>Give Praise at the Perfect Moment</h1>
                        <subtitle>Supervisors give out Scratch Cards when they see good work</subtitle>
                    </div>
                    <div className='feature-info'>
                        <p>Psychological studies have shown that rewarding an action as quickly as possible to when it occurred leads to a higher chance of that person repeating that action again. Scratch Cards allow supervisors to incentivize their coworkers immediately after a positive action by giving them a scratch card. Scratch cards can have values varying from 5 to 200 points to match your company culture, and are redeemable within the Workhub ecosystem.</p>
                    </div>
                    <div className='sc_feature_points'>
                        <div className='sc_feature_list'>

                            <img src='/app-icons/layout-icons/check-mark-line.svg' alt="checkmark"  />
                            <p><strong>Recognize Exemplary Actions:</strong> </p>
                            <div>
                                <p>Allow supervisors to reward positive actions in the workplace immediately by giving them a set of scratch cards. Direct reports can then redeem their scratch cards towards items in the Workhub ecosystem.</p>
                            </div>

                            <img src='/app-icons/layout-icons/check-mark-line.svg' alt="checkmark"  />
                            <p><strong>Great for Any Occasion:</strong> </p>
                            <p>Scratch cards can be used as supervisor to direct report recognition, but they are also great to hand out at social events, holidays, meetings and more!</p>

                            <img src='/app-icons/layout-icons/check-mark-line.svg' alt="checkmark"  />
                            <p> <strong>Match Your Budget:</strong> </p>
                            <p>Low, High and Precious value Scratch Card sets can be purchased to meet your organization’s incentivization needs, culture and budget.</p>
                        </div>
                    </div>
                </section> 
                <section className='feature-section'>
                    <SignUpBtn/>
                </section>

                <section className='feature-section'>
                    <div className='sc_feature_options'>
                        <ReactWOW animation="fadeInLeft" delay="0.1s">
                            <div className='sc_images_overlap'>
                                <img src="/features/scratch-cards/scratch-cards-template.png" alt="scratch cards program page" />
                            </div>
                        </ReactWOW>
                        <div>
                            <h1>Tailor-Made Tokens of Thanks</h1>
                            <p> 
                                Scratch Cards are customizable to suit your needs. Customization options include: 
                            </p>
                            <ul>
                                <li>Point values to match your needs and budget (Low, High, Precious)</li>
                                <li>Organization branding including logo, colors and thank-you message</li>
                                <li>Patterns to match your style and/or occasion (including holiday sweater!)</li> 
                                <li>Assign sets to supervisors to keep track of how many scratch cards have been redeemed and who is in charge of the set.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='sc_feature_options'>
                        <div>
                            <h1>A Scratch Away from Something Great</h1>
                            <p>
                                Once a worker has received a scratch card, all they have to do is log into Workhub, enter the code/amount, and browse the Rewards catalog with their new-found points! 
                                <br />
                                <br />
                                Scratch card values can vary from 5 to 200 points depending on the set, and can be used towards one of over 1000 items in the Rewards catalog.
                            </p>
                        </div>
                        <ReactWOW animation="fadeInRight" delay="0.1s">
                            <div>
                                <img src="/features/scratch-cards/scratch-cards-mobile.png" alt="Scratch cards mobile view" />
                            </div>
                        </ReactWOW>
                    </div>
                </section>
                <section className='feature-section steps' id="overview-cards"> 
                    <h1>How it Works</h1>
                    <div className='sc_how_it_works'>
                        <div className='empty'></div>
                        <Badge className="badge" content="1" size="large"/>
                        <ReactWOW animation="fadeInRight" delay="0.1s">
                            <div className='_option'>
                                <p className='step-header'>Setup Your Program</p>
                                <p>Customize scratch cards with your logo, company colors, a thank you message, budget and pattern to suit your needs.</p>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className='sc_how_it_works'>
                    <ReactWOW animation="fadeInLeft" delay="0.2s">
                        <div className='_option'>
                            <p className='step-header'>Let Your Company Culture Grow</p>
                            <p>Supervisors can give out Scratch Cards whenever they see fit (or as otherwise dictated by your company culture).</p>
                        </div>
                    </ReactWOW>

                        <Badge className="badge" content="2" size="large"/>
                        <div className='empty'></div>
                    </div>

                    <div className='sc_how_it_works'>
                        <div className='empty'></div>
                        <Badge className="badge" content="3" size="large"/>
                        <ReactWOW animation="fadeInRight" delay="0.3s">
                            <div className='_option'>
                                <p className='step-header'>See the Results</p>
                                <p>See the excitement and company culture grow as your workforce gets rewarded for exemplary behavior. </p>
                            </div>
                        </ReactWOW>

                    </div>

                </section>
            </main>
        </Layout>
    )
 }